<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Surat Jalan</h5>
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="onRefreshClick">mdi-refresh</v-icon>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="getSupplierAirwayBills"
            :loading="getLoading"
            loading-text="Sedang Menyiapkan Data..."
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td
                  class="detailLink"
                  @click="getAirwayBillDetailById(props.item.shipping_id)"
                >
                  {{ props.item.shipping_number }}
                </td>
                <td>{{ getDate(props.item.shipping_date) }}</td>
                <td>{{ props.item.po_number }}</td>
                <td>{{ props.item.driver_name }}</td>
                <td>{{ props.item.vehicle_type }}</td>
                <td>{{ props.item.vehicle_number }}</td>
                <td>{{ props.item.expedition }}</td>
                <td>{{ props.item.store_name }}</td>
                <td>{{ props.item.shipping_status }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>
              Nomor Surat Jalan:
              {{ getSupplierAirwayBillDetail.shipping_number }}
            </h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="printAirwayBill(getSupplierAirwayBillDetail.shipping_id)"
            :loading="getLoading"
            :disabled="getLoading"
            >Cetak Surat Jalan</v-btn
          >
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Nama Toko Bangunan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ getSupplierAirwayBillDetail.store_name }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Tanggal Pengiriman
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp;
                  {{ getDate(getSupplierAirwayBillDetail.shipping_date) }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Alamat Toko Bangunan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ getSupplierAirwayBillDetail.store_address }}
                </div>
              </v-col>
            </v-row>

            <v-card class="my-10">
              <v-app-bar dense color="transparent">
                <v-toolbar-title>
                  <h5>Daftar Produk</h5>
                </v-toolbar-title>
              </v-app-bar>

              <v-card-text class="pa-0">
                <template>
                  <v-data-table
                    :headers="headersDetail"
                    :items="getSupplierAirwayBillDetail.details"
                    class="elevation-0"
                    mobile-breakpoint="0"
                  >
                    <template slot="item" slot-scope="props">
                      <tr>
                        <td>{{ props.item.product_name }}</td>
                        <td>{{ props.item.product_code }}</td>
                        <td>{{ props.item.quantity_order }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";
import { mapGetters } from "vuex";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      headers: [
        { text: "Nomor SJ", sortable: false },
        { text: "Tanggal Pengiriman", sortable: false },
        { text: "Nomor PO", sortable: false },
        { text: "Nama Driver", sortable: false },
        { text: "Jenis Kendaraan", sortable: false },
        { text: "Nomor Kendaraan", sortable: false },
        { text: "Expedisi", sortable: false },
        { text: "Nama Toko Bangunan", sortable: false },
        { text: "Status Pengiriman", sortable: false }
      ],
      headersDetail: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Jumlah", sortable: false }
      ]
    };
  },

  computed: {
    ...mapGetters(["getLoading"]),
    ...mapGetters("airwayBill", ["getSupplierAirwayBills"]),
    ...mapGetters("airwayBill", ["getSupplierAirwayBillDetail"])
  },

  methods: {
    getAirwayBillDetailById(id) {
      this.$root.$loaderModal.start("Loading...");

      let params = {
        id: id
      };

      this.$store
        .dispatch("airwayBill/getAirwayBillDetailById", params)
        .then(() => (this.dialog = true))
        .catch(() =>
          this.$tostini({
            message: ERROR_SOMETHING_WRONG,
            type: "error"
          })
        )
        .finally(() => this.$root.$loaderModal.hide());
    },
    printAirwayBill(id) {
      let params = {
        id: id,
        airwayBillNo: this.getSupplierAirwayBillDetail.shipping_number
      };

      this.$store.dispatch("airwayBill/printAirwayBill", params).catch(() =>
        this.$tostini({
          message: this.$store.state.message,
          type: "error"
        })
      );
    },
    onRefreshClick() {
      this.$store.dispatch("airwayBill/getSupplierAirwayBills");
    }
  }
};
</script>
